import {
  ADMIN_SYS,
  ADMINISTRADOR,
  CAIXA,
  GESTAO,
  COMPRAS,
  CONTABIL,
  CONTROLADORIA,
  EMISSOR_NFE,
  FINANCEIRO_GERENTE,
  OPERACIONAL,
} from "@/constants/profiles.js";

export default [
  {
    path: "/compras",
    name: "compras.home",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/home/MenuCompras.vue"),
  },
  {
    path: "/compras/docs/nfe/importar",
    name: "compras.docs.nfe",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/compras/importar-NFe.vue"),
  },
  {
    path: "/compras/docs/nfe/gerenciar",
    name: "compras.docs.nfe.gerenciar",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/compras/gerenciar-notas-importadas.vue"),
  },
  {
    path: "/compras/docs/cte/gerenciar",
    name: "compras.docs.cte.gerenciar",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cte/gerenciar-cte-importados.vue"),
  },
  {
    path: "/compras/docs/cte/importar",
    name: "compras.cte",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        EMISSOR_NFE,
        GESTAO,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cte/importar-cte.vue"),
  },
  {
    path: "/compras/docs/nfe/:id",
    name: "compras.docs.nfe.importada",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        EMISSOR_NFE,
        GESTAO,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/compras/nota-importada.vue"),
  },
  {
    path: "/compras/docs/nfe-industria/:id",
    name: "compras.docs.nfe.importada-industria",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        EMISSOR_NFE,
        GESTAO,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/compras/nota-importada-industria.vue"),
  },
  {
    path: "/compras/importacao/nfse-importada-industria/:id",
    name: "compras.importacao.nfse.importada-industria",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        EMISSOR_NFE,
        GESTAO,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/compras/servico/nfse-importada-industria.vue"),
  },
  {
    path: "/compras/cadastros",
    name: "compras.cadastros",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        GESTAO,
        OPERACIONAL,
        EMISSOR_NFE,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/cadastros/home-cadastros.vue"),
  },
  {
    path: "/compras/ordem-compras",
    name: "compras.compras",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/compras/SubmenuComprasOrdemCompras.vue"),
  },
  {
    path: "/compras/docs",
    name: "compras.docs",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/compras/SubmenuComprasDocsFiscais.vue"),
  },
  {
    path: "/compras/cotacao",
    name: "compras.cotacao.home",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/cotacao/home-cotacao.vue"),
  },
  {
    path: "/compras/cadastros/fornecedores/cadastro",
    name: "fornecedores.cadastro",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        GESTAO,
        OPERACIONAL,
        EMISSOR_NFE,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/cadastros/fornecedor/form-fornecedor.vue"),
  },
  {
    path: "/compras/cadastros/fornecedores/editar/:id",
    name: "fornecedores.edit",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/cadastros/fornecedor/form-fornecedor.vue"),
  },
  {
    path: "/compras/cadastros/fornecedores",
    name: "fornecedores",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/cadastros/fornecedor/fornecedores.vue"),
  },
  {
    path: "/compras/relatorios",
    name: "compras.relatorios",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        COMPRAS,
        GESTAO,
        OPERACIONAL,
        CAIXA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/relatorios/home-relatorios.vue"),
  },
  {
    path: "/compras/cotacao/mat/:CodMatPri",
    name: "compras.cotacao.mat",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/cotacao/nova-cotacao.vue"),
  },
  {
    path: "/compras/cotacao/pro/:CodPro",
    name: "compras.cotacao.pro",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/cotacao/nova-cotacao.vue"),
  },
  {
    path: "/compras/cotacao/materia-prima",
    name: "compras.cotacao.materia-prima",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/cotacao/cotacao.vue"),
  },
  {
    path: "/compras/cotacao/produtos",
    name: "compras.cotacao.produtos",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/cotacao/cotacao-produtos.vue"),
  },
  {
    path: "/compras/cadastros/produtos-compras",
    name: "compras.cadastros.produtos-compras",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/cadastros/produtos/produtos-compras.vue"),
  },
  {
    path: "/compras/cadastros/cadastro-produtos-compras",
    name: "compras.cadastros.cadastro-produtos-compras",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/cadastros/produtos/editor-produto-compras.vue"),
  },
  {
    path: "/compras/cadastros/cadastro-produtos-compras/editar/:id",
    name: "produtos-compras.edit",
    component: () => import("@/components/compras/cadastros/produtos/editor-produto-compras.vue"),
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
  },
  {
    path: "/compras/ordem-compras/gerenciar/novo",
    name: "compras.nova-ordem-compra",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/compras/nova-ordem-compra.vue"),
  },

  {
    path: "/compras/ordem-compras/gerenciar",
    name: "compras.gerenciar-ordem-compra",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/compras/gerenciar-ordem-compra.vue"),
  },
  {
    path: "/compras/ordem-compra/edit/:id",
    name: "ordem-compra.edit",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/compras/nova-ordem-compra.vue"),
  },
  {
    path: "/compras/ordem-compra/:id",
    name: "ordem-compra.show",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/compras/gerenciar-ordem-compra-show.vue"),
  },
  {
    path: "/compras/relatorios/ressuprimento",
    name: "compras.relatorios.ressuprimento",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/relatorios/ressuprimento.vue"),
  },
  {
    path: "/compras/relatorios/custo-compras",
    name: "compras.relatorios.custo-compras",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, GESTAO, CAIXA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/compras/relatorios/custo-compras.vue"),
  },
];
