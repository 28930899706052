import {
  ADMINISTRADOR,
  ADMIN_SYS,
  COMPRAS,
  CONSULTA_FINANCEIRO,
  CONTABIL,
  CONTROLADORIA,
  EMISSOR_NFE,
  FINANCEIRO_GERENTE,
} from "@/constants/profiles.js";

export default [
  {
    path: "/e-commerce",
    name: "e-commerce",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, EMISSOR_NFE, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/e-commerce/MenuECommerce.vue"),
  },
  {
    path: "/e-commerce/nuvend",
    name: "e-commerce.nuvend",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/e-commerce/SubmenuECommerceNuvend.vue"),
  },
  {
    path: "/e-commerce/configuracoes",
    name: "e-commerce.configuracoes",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, EMISSOR_NFE, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/e-commerce/SubmenuECommerceConfiguracoes.vue"),
  },
  {
    path: "/e-commerce/configuracoes/aliquotas-diretas",
    name: "mva.aliquota.direta",
    component: () => import("@/components/faturamento/configuracoes/aliquotas/mva-aliquota-direta.vue"),

    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/e-commerce/configuracoes/tarifas-cartao/novo",
    name: "tarifa-credito.cadastro",
    component: () => import("@/components/faturamento/nfe/editor-tarifa-cartao-credito.vue"),

    meta: {
      authorize: [ADMIN_SYS, FINANCEIRO_GERENTE],
    },
  },
];
