<template>
  <div class="d-flex justify-content-between align-items-center">
    <h4 class="font-weight-light py-4 mb-0">
      <BBreadcrumb
        class="mb-0"
        :items="concatenatedItems"
      />
    </h4>
  </div>
</template>

<script>
import { EMISSOR_NFE, GESTAO } from "@/constants/profiles.js";
export default {
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      ocultarPorPerfil: {
        [EMISSOR_NFE]: ["Faturamento", "Estoque", "Compras", "Logística", "RH", "Dashboard", "Docs. Fiscais"],
        [GESTAO]: ["Logística", "RH", "Dashboard", "Docs. Fiscais"],
      },
      home: [
        {
          text: "Início",
          to: "/home",
        },
      ],
    };
  },
  computed: {
    concatenatedItems() {
      const perfil = this.currentUser.role;
      const itens = this.home.concat(this.items);

      if (this.ocultarPorPerfil[perfil]) {
        const modulosEscondidos = this.ocultarPorPerfil[perfil];
        return itens.filter((item) => !modulosEscondidos.includes(item.text));
      }

      return itens;
    },
  },
};
</script>
