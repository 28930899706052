import { createRouter, createWebHistory } from "vue-router";
import { ADMIN_SYS, NAO_APROVADO } from "@/constants/profiles.js";
import { sleep } from "@/middleware/helper-functions.js";
import { useNotification } from "@/composables/notify.js";
import appStore from "@/store/app.js";
import auth from "./auth.js";
import authStore from "@/store/auth.js";
import blank from "./blank.js";
import compras from "./compras.js";
import comercio from "./comercio/comercio.js";
import configuracoes from "./configuracoes.js";
import controladoria from "./controladoria.js";
import contabilidade from "./contabilidade.js";
import crm from "./crm.js";
import dashboard from "./dashboards.js";
import eCommerce from "./e-commerce.js";
import emitenteStore from "@/store/emitente.js";
import equipe from "./equipe.js";
import recebimento from "./recebimento.js";
import estoque from "./estoque.js";
import faturamento from "./faturamento.js";
import financeiro from "./financeiro.js";
import historyStore from "@/store/history.js";
import home from "./home.js";
import logistica from "./logistica.js";
import pcp from "./pcp.js";
import pontoDeVenda from "./ponto-de-venda.js";
import produtividade from "./produtividade.js";
import rh from "./rh.js";
import root from "./root.js";
import site from "./site.js";
import sobre from "./sobre.js";
import tintometrico from "./tintometrico.js";
import vendas from "./vendas.js";

root.children = [
  ...compras,
  ...configuracoes,
  ...controladoria,
  ...contabilidade,
  ...comercio,
  ...crm,
  ...dashboard,
  ...eCommerce,
  ...equipe,
  ...estoque,
  ...recebimento,
  ...faturamento,
  ...financeiro,
  ...home,
  ...logistica,
  ...pcp,
  ...pontoDeVenda,
  ...produtividade,
  ...rh,
  ...site,
  ...sobre,
  ...tintometrico,
  ...vendas,
];

export const router = createRouter({
  history: createWebHistory(),
  routes: [...auth, root, blank],
  scrollBehavior(to) {
    if (to.hash) {
      return { behavior: "smooth", el: to.hash };
    }
    return { left: 0, top: 0 };
  },
});

const ensureAppIsMounted = async () => {
  while (!appStore.state.mounted) {
    await sleep(100);
  }
};
const ensureUserIsFetched = async () => {
  while (authStore.getCurrentUser().role === undefined) {
    await sleep(100);
  }
};

const userIsGuest = () => !authStore.isAuthenticated();
const userIsUnapproved = () => authStore.getCurrentUser().role == NAO_APROVADO;
const userIsAuthorized = (authorize) =>
  !authorize ||
  authorize.includes(Number(authStore.getCurrentUser().role)) ||
  Number(authStore.getCurrentUser().role) === ADMIN_SYS;

const emitenteIsUnset = () => !("identificador" in emitenteStore.getEmitente());

const { notify } = useNotification();

router.beforeEach(async (to, from) => {
  await ensureAppIsMounted();
  historyStore.state.from = from.fullPath;

  if (to.path === "/" && authStore.isAuthenticated()) {
    return { name: "home" };
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresEmitente = to.matched.some((record) => record.meta.requiresEmitente);
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest);

  if (!(requiresAuth || requiresGuest)) {
    notify({ text: "Essa rota não foi configurada, contate o suporte!", type: "error" });
    return false;
  }

  if (requiresGuest) {
    return userIsGuest() ? true : { name: "home" };
  }

  if (requiresAuth && userIsGuest()) {
    return { name: "login" };
  }

  await ensureUserIsFetched();

  if (userIsUnapproved()) {
    return to.name === "confirmacao" ? true : { name: "confirmacao" };
  }

  if (to.name === "confirmacao") {
    return { name: "home" };
  }

  if (requiresEmitente && emitenteIsUnset()) {
    return { name: "emitentes" };
  }

  if (userIsAuthorized(to.meta.authorize)) {
    return true;
  }

  notify({ text: "Você não tem permissão para prosseguir!", type: "error" });
  return false;
});
