import { ADMIN_SYS, LABORATORIO, PUBLICIDADE, FINANCEIRO_GERENTE } from "@/constants/profiles.js";

export default [
  {
    path: "/site",
    name: "site",
    component: () => import("@/components/home/MenuSite.vue"),
    meta: {
      authorize: [ADMIN_SYS, PUBLICIDADE, FINANCEIRO_GERENTE],
    },
  },
  {
    path: "/site/banners",
    name: "site.banners",
    component: () => import("@/components/site/banners/lista-de-banners.vue"),
  },
  {
    path: "/site/banners/novo",
    name: "site.banners.novo",
    component: () => import("@/components/site/banners/editor-banner.vue"),
  },
  {
    path: "/site/banners/:id/editar",
    name: "site.banners.editar",
    component: () => import("@/components/site/banners/editor-banner.vue"),
  },
  {
    path: "/site/blog",
    name: "site.blog.home",
    component: () => import("@/components/site/blog/home-blog.vue"),
  },
  {
    path: "/site/blog/posts/gerenciar",
    name: "site.blog.todos-posts",
    component: () => import("@/components/site/blog/lista-de-posts.vue"),
  },
  {
    path: "/site/blog/posts/novo",
    name: "site.blog.adicionar-novo",
    component: () => import("@/components/site/blog/editor-post.vue"),
  },
  {
    path: "/site/blog/:id/edit",
    name: "site.blog.edit",
    component: () => import("@/components/site/blog/editor-post.vue"),
  },
  {
    path: "/site/institucional",
    name: "site.institucional.home",
    component: () => import("@/components/site/institucional/home-institucional.vue"),
  },
  {
    path: "/site/institucional/sobre-nos",
    name: "site.institucional.sobre-nos",
    component: () => import("@/components/site/institucional/sobre-nos.vue"),
  },
  {
    path: "/site/institucional/missao-visao-valores",
    name: "site.institucional.missao-visao-valores",
    component: () => import("@/components/site/institucional/missao-visao-valores.vue"),
  },
  {
    path: "/site/institucional/meio-ambiente",
    name: "site.institucional.meio-ambiente",
    component: () => import("@/components/site/institucional/meio-ambiente.vue"),
  },
  {
    path: "/site/institucional/politica-privacidade",
    name: "site.institucional.politica-privacidade",
    component: () => import("@/components/site/institucional/politica-privacidade.vue"),
  },
  {
    path: "/site/produtos",
    name: "site.produtos.home",
    component: () => import("@/components/site/produtos/home-produtos.vue"),
  },
  {
    path: "/site/produtos/gerenciar",
    name: "site.produtos",
    component: () => import("@/components/site/produtos/lista-de-produtos.vue"),
    meta: {
      authorize: [ADMIN_SYS, PUBLICIDADE, LABORATORIO, FINANCEIRO_GERENTE],
    },
  },
  {
    path: "/site/produtos/add",
    name: "site.produtos.add",
    component: () => import("@/components/site/produtos/editor-produto.vue"),
  },
  {
    path: "/site/produtos/:id/edit",
    name: "site.produtos.editar",
    component: () => import("@/components/site/produtos/editor-produto.vue"),
  },
  {
    path: "/site/produtos/categorias",
    name: "site.categorias",
    component: () => import("@/components/site/produtos/lista-de-categorias.vue"),
    meta: {
      authorize: [ADMIN_SYS, PUBLICIDADE, LABORATORIO, FINANCEIRO_GERENTE],
    },
  },
  {
    path: "/site/produtos/categorias/novo",
    name: "site.categorias.novo",
    component: () => import("@/components/site/produtos/editor-categoria.vue"),
  },
  {
    path: "/site/produtos/categorias/:id/edit",
    name: "site.categorias.editar",
    component: () => import("@/components/site/produtos/editor-categoria.vue"),
  },
  {
    path: "/site/produtos/subcategorias",
    name: "site.subcategorias",
    component: () => import("@/components/site/produtos/lista-de-subcategorias.vue"),
    meta: {
      authorize: [ADMIN_SYS, PUBLICIDADE, LABORATORIO, FINANCEIRO_GERENTE],
    },
  },
  {
    path: "/site/subcategorias/novo",
    name: "site.subcategorias.novo",
    component: () => import("@/components/site/produtos/editor-subcategoria.vue"),
  },
  {
    path: "/site/subcategorias/:id/edit",
    name: "site.subcategorias.editar",
    component: () => import("@/components/site/produtos/editor-subcategoria.vue"),
  },
  {
    path: "/site/simuladores/cadastro-cores/novo",
    name: "site.simuladores.cores.novo",
    component: () => import("@/components/site/simuladores/form-simulador-ambiente-cores.vue"),
  },
  {
    path: "/site/simuladores/cadastro-cores/:id",
    name: "site.simuladores.cores.editar",
    component: () => import("@/components/site/simuladores/form-simulador-ambiente-cores.vue"),
  },
  {
    path: "/site/simuladores/cores",
    name: "site.simuladores.cores",
    component: () => import("@/components/site/simuladores/simulador-ambiente-cores.vue"),
  },
  {
    path: "/site/simuladores/cadastro-imagens/novo",
    name: "site.simuladores.imagens.novo",
    component: () => import("@/components/site/simuladores/form-simulador-ambiente-imagens.vue"),
  },
  {
    path: "/site/simuladores/cadastro-imagens/:id",
    name: "site.simuladores.imagem.editar",
    component: () => import("@/components/site/simuladores/form-simulador-ambiente-imagens.vue"),
  },
  {
    path: "/site/simuladores/imagens",
    name: "site.simuladores.imagens",
    component: () => import("@/components/site/simuladores/simulador-ambiente-imagens.vue"),
  },
  {
    path: "/site/simuladores",
    name: "site.simuladores.home",
    component: () => import("@/components/site/simuladores/HomeSimuladores.vue"),
  },
];
