import "@/mixins/global/globalDirectives.js";
import "bootstrap";
import { createApp } from "vue";
import { router } from "@/router/routes.js";
import { SweetModal } from "sweet-modal-vue-3";
import App from "@/App.vue";
import axios from "axios";
import BootstrapVue from "bootstrap-vue";
import Breadcrumb from "@/components/comum/breadcrumb.vue";
import checkForUpdates from "@/checkForUpdates.js";
import Cookies from "js-cookie";
import DebugCode from "@/components/DebugCode.vue";
import DialogModal from "@/components/comum/DialogModal.vue";
import globalComputed from "@/mixins/global/globalComputed.js";
import globalData from "@/mixins/global/globalData.js";
import globalMethods from "@/mixins/global/globalMethods.js";
import jQuery from "jquery";
import LayoutButton from "@/components/comum/buttons/LayoutButton.vue";
import LayoutToolbar from "@/components/comum/layout/toolbar/LayoutToolbar.vue";
import LoadingWrapper from "@/components/comum/animations/loading-wrapper.vue";
import moment from "moment";
import Notifications from "@kyvg/vue3-notification";
import numeral from "numeral";
import Popper from "popper.js";
import ScaffoldSubmenu from "./layout/ScaffoldSubmenu.vue";
import SeparatorDot from "@/components/comum/separators/separator-dot.vue";
import VueNumber from "vue-number-animation";
import VueNumeric from "vue-numeric";

// --------------------------- GLOBAL PROPERTIES ---------------------------
window.$ = window.jQuery = jQuery;
window.axios = axios;
window.Cookies = Cookies;
window.Popper = Popper;

// ------------------------------ AXIOS CONFIG ------------------------------
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// CSRF Token
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
  console.error("CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token");
}

// Auth Bearer Token
let bearer = Cookies.get("access_token");
if (bearer) {
  axios.defaults.headers.common["Authorization"] = bearer;
}

// ------------------------------- LOCALE CONFIG -------------------------------
numeral.locale("br");
numeral.register("locale", "br", { currency: { symbol: "R$" }, delimiters: { decimal: ",", thousands: "." } });
moment.locale("pt-br");

// ------------------------------- VUE APP SETUP -------------------------------
const app = createApp(App);

// ------------------------------- VUE MIXINS -------------------------------
app.mixin({ data: globalData, computed: globalComputed, methods: globalMethods });

// --------------------------------- VUE.USE --------------------------------
app.use(router);
app.use(BootstrapVue);
app.use(Notifications);
app.use(VueNumber);
app.use(VueNumeric);

// ------------------------------ VUE.COMPONENT -----------------------------
app.component("Breadcrumb", Breadcrumb);
app.component("DebugCode", DebugCode);
app.component("DialogModal", DialogModal);
app.component("LayoutButton", LayoutButton);
app.component("LayoutToolbar", LayoutToolbar);
app.component("LoadingWrapper", LoadingWrapper);
app.component("ScaffoldSubmenu", ScaffoldSubmenu);
app.component("SeparatorDot", SeparatorDot);
app.component("SweetModal", SweetModal);

// ------------------------------- VUE APP ---------------------------------
app.mount("#app");

checkForUpdates();
