<template>
  <div>
    <div class="row no-gutters">
      <div class="col-md">
        <Breadcrumb :items="toolbar.breadcrumbs" />
      </div>
      <div class="col-md-auto py-3">
        <LayoutButton
          v-for="(button, index) in toolbar.buttons"
          :key="index"
          class="mr-2 mr-md-0 ml-md-2"
          :disabled="button.disabled"
          :icon="button.icon"
          :to="button.to"
          variant="btn btn-outline-secondary"
          @click="button.click"
        >
          {{ button.title }}
        </LayoutButton>
      </div>
    </div>

    <BCard
      v-if="toolbar.filters"
      body
      class="mb-4"
    >
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <div class="row">
            <div class="col-auto">
              <BFormInput
                v-model="filterParams.search"
                placeholder="Buscar"
                style="min-width: 20em !important"
                type="search"
                @keyup.enter="onSubmitSearch"
              />
            </div>
            <div class="col-auto">
              <ToolbarButtonDate @filter-date="onFilterDate" />
            </div>
            <LayoutButton
              v-if="toolbarState.search"
              filter
              @click="onClearSearch"
            >
              "{{ toolbarState.search }}"
            </LayoutButton>

            <div
              v-for="(filter, index) in toolbar.filters"
              :key="index"
            >
              <LayoutButton
                v-if="filter.value"
                filter
                @click="filter.click"
              >
                {{ filter.value }}
              </LayoutButton>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div class="row">
            <div class="col">
              <LayoutButton
                v-b-modal.modal-1
                icon="ph-funnel-simple"
                @click="$emit('filter-clicked')"
              >
                Filtros
              </LayoutButton>
            </div>
          </div>
        </div>
      </div>
    </BCard>

    <div class="row">
      <div
        v-for="(stat, index) in toolbar.stats"
        :key="index"
        class="col-sm-6 col-md"
      >
        <BCard class="mb-4">
          <div class="d-flex align-items-center">
            <i
              class="display-4"
              :class="stat.icon"
            ></i>
            <div class="ml-3 w-100">
              <div class="text-muted small">{{ stat.title }}</div>
              <div class="text-large">
                <div v-if="typeof stat.value === 'number'">
                  <span>{{ maskNumber(stat.value) }}</span>
                  <span v-if="stat.suffix">{{ stat.suffix }}</span>
                </div>
                <div v-else>
                  <span>{{ stat.value }}</span>
                </div>
              </div>
            </div>
            <!-- <i class="ion ion-md-funnel text-muted small"></i> -->
          </div>
        </BCard>
      </div>
    </div>
  </div>
</template>

<script>
import ToolbarButtonDate from "./components/ToolbarButtonDate.vue";
import mixinToolbar from "@/components/comum/layout/toolbar/mixinToolbar.js";

export default {
  components: {
    ToolbarButtonDate,
  },
  mixins: [mixinToolbar],
  props: {
    toolbar: {
      default: () => {
        return {
          filters: {
            show: false,
          },
        };
      },
      type: Object,
    },
  },
  data() {
    return {
      filterParams: { search: "" },
      isStatsAnimationCompleted: false,
      statsChangeTrigger: 0,
    };
  },
  watch: {
    "toolbar.stats": {
      handler() {
        this.isStatsAnimationCompleted = false;
        this.statsChangeTrigger++;
      },
      deep: true,
    },
  },
  created() {
    this.clearToolbarState();
  },
  methods: {
    onClearSearch() {
      this.$set(this.filterParams, "search", "");
      this.storeToolbarSearch("");
      this.$emit("submit-search");
    },
    onFilterDate() {
      this.$emit("filter-date");
    },
    onStatsAnimationCompleted() {
      this.isStatsAnimationCompleted = true;
    },
    onSubmitSearch() {
      this.storeToolbarSearch(this.filterParams.search);
      this.$emit("submit-search");
    },
    statsNumberFormat(number) {
      return Math.round(number);
    },
  },
};
</script>
